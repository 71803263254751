<template>
  <div class="content-info">
    <div class="info">
      <div class="info-right">
        <div class="info-content size-12">  <div class="info-title size-18">{{ detail.title }}</div>{{ detail.details }}</div>
      </div>
      <div class="info-left">
        <img :src="detail.img" :alt="detail.title" />
      </div>
      <div style="clear:both;"></div>
    </div>

    <div class="about-item">
      <div class="item-block">
        <div class="item-block-title size-14">箱柜制造经验</div>
        <div class="item-block-info size-14">
          <span class="item-block-num size-60 bold-title">27</span>年
        </div>
      </div>
      <div class="item-block item-block-left">
        <div class="item-block-title size-14">发明专利</div>
        <div class="item-block-info size-14">
          共<span class="item-block-num size-60 bold-title">77</span>+
        </div>
      </div>
    </div>

    <div class="scale-content">
      <div class="bold-title scale-title size-20 bold-title">规模制作实力</div>
      <div class="text-doru"></div>
      <div class="scale-info">
        <div class="scale-item">
          <img src="@/assets/about-factory.png" alt="规模" />
        </div>
        <div class="scale-item">
          <img src="@/assets/about-team.png" alt="实力" />
        </div>
      </div>
    </div>

    <div class="content-mian">
      <ul class="choose-ul">
        <li>
          <span class="li-num size-40 bold-title">66000</span>
          <span class="li-desc size-16">㎡</span>
          <div class="li-title size-16">总占地面积</div>
        </li>
        <li class="choose-li-left">
          <span class="li-num size-40 bold-title">200</span>
          <span class="li-desc size-16">+台</span>
          <div class="li-title size-16">现代化设备</div>
        </li>
        <li class="choose-li-left">
          <span class="li-num size-40 bold-title">2</span>
          <span class="li-desc size-16">条</span>
          <div class="li-title size-16">柔性生产线</div>
        </li>
        <li class="choose-li-left">
          <span class="li-num size-40 bold-title">10</span>
          <span class="li-desc size-16">年以上</span>
          <div class="li-title size-16">工人平均工作经验</div>
        </li>
        <li class="choose-li-left">
          <span class="li-num size-40 bold-title">12w</span>
          <span class="li-desc size-16">台</span>
          <div class="li-title size-16">智能箱生产能力</div>
        </li>
      </ul>
      <div class="equipment-content" v-show="!isShow">
        <div class="bold-title size-20 content-title">成熟技术设备</div>
        <div class="text-doru"></div>
        <Slider :slides="equList" v-slot="{ currentSlide }">
            <div class="product-slider" v-for="(item,key) in currentSlide" :key="key">
                <img  :alt="item.title" :title="item.title" :src="item.img" class="product-slider-img">
                <div class="product-slider-title">{{ item.title }}</div>
            </div>
        </Slider>
      </div>

       <div class="equipment-content" v-show="isShow">
        <div class="bold-title size-20 content-title">成熟技术设备</div>
        <div class="text-doru"></div>
        <Slider :slides="equipmentList" v-slot="{ currentSlide }">
            <div class="product-slider" v-for="(item,key) in currentSlide" :key="key">
                <img :alt="item.title" :title="item.title" :src="item.img" class="product-slider-img">
                <div class="product-slider-title">{{ item.title }}</div>
            </div>
        </Slider>
      </div>
    </div>

  </div>
</template>

<script>

export default {
  name: 'AboutIndex',
  data() {
    return {
      screenWidth:document.body.clientWidth,
      isShow:false,
      detail:{
        img:require('@/assets/about-right.png'),
        title:'湖北易宝通智能科技有限公司',
        details:'湖北易宝通智能科技有限公司是深圳市速易宝智能科技有限公司的全资控股子公司，位于长江经济带国家级转型升级示范开发区一流水经济开发区散花工业园，工业园占地40亩，有标准化的银金、喷涂、装配等生产制造车间五栋，各类生产设备齐全，拥有全自动机器人冲折一体化生产线、大型激光切割机、多功能折弯机、数控冲床、全自动喷涂生产流水线等。公司拥有77+项发明实用新型等专利，广东、湖北两大生产基地，每年12万台套柜机市场验证。项目覆盖全国40多个省市区，10000+成功案例，与丰巢、饿了么、农夫山泉、三一重工、长江存储、华润、万科等行业领军者建立了战略合作关系。',
      },
       equipmentList:[
        [
          {
            title:'4.0柔性生产线',
            img:require('@/assets/strength.png')
          },
          {
            title:'钣金',
            img:require('@/assets/metal.png')
          }
        ],
        [
          {
            title:'焊接',
            img:require('@/assets/weld.png')
          },
          {
            title:'激光切割',
            img:require('@/assets/cutting.png')
          }
        ]
      ],
      equList:[
        [
          {
            title:'4.0柔性生产线',
            img:require('@/assets/strength.png')
          },
          {
            title:'钣金',
            img:require('@/assets/metal.png')
          },
          {
            title:'焊接',
            img:require('@/assets/weld.png')
          },
          {
            title:'激光切割',
            img:require('@/assets/cutting.png')
          }
        ]
      ],
    }
  },
   mounted() { 
    this.checkLength(this.screenWidth)
    let _this = this
     window.addEventListener("resize", () => { 
      return (() => {
          _this.screenWidth = document.body.clientWidth
      })()
     })
  },
 
   watch: {
    screenWidth: function (n) {
      console.log('xxx')
      this.checkLength(n)
    }
  },
  methods: {
    checkLength(n) {
      // console.log(n)
      if(n <= 751) {
        this.isShow= true
      }else {
        this.isShow= false
      }
    } 
  }
}
</script>

<style scoped>
  .info-right {
    padding:0;
    padding-right:4%;
  }

  .about-item{
    padding:0px 10%;
  }

  .item-block {
    width:30%;
    display: inline-block;
  }

  .item-block-left {
    padding:10px 0px 10px 20px;
    border-left:1px solid #e5e5e5;
  }

  .item-block-title {
    padding-left:20px;
  }

  .scale-content {
    padding:0px 10%;
  }

  .scale-title {
    text-align: center;
    padding:10px 0;
  }

  .scale-item {
    display: inline-block;
    margin:0px 1%;
    /* width:32%; */
    /* max-height:24px; */
    position:relative;
    width:48%;
    height:0;
    padding-top:32%;
    margin-top:40px;
    /* margin-right:12rpx; */
  }
 .scale-item img {
    display: inline-block;
    /* width:100%; */
    width:100%;
    height:100%;
    top:0;
    left:0;
    position: absolute;
  }

  .content-mian{
    padding:0 10%;
  }

.mian-gray {
  background-color: #f0efef;
  padding-bottom:60px;
}

.content-title {
  width: 100%;
  text-align: center;
  padding:20px 0;
}

.content-sub-title {
  width: 100%;
  text-align: center;
}

.choose-ul {
  padding:60px 0;
  display: flex;
}

.choose-ul li {
  display: inline-block;
  width:20%;
  text-align: center;
}

.choose-li-left {
  border-left:1px solid #e5e5e5;
}

.equipment-content {
  background-color: #fff;
  margin-bottom:40px;
}

.product-slider {
    display: inline-block;
    margin:0px 1%;
    /* width:32%; */
    /* max-height:24px; */
    position:relative;
    width:23%;
    height:0;
    padding-top:15.3%;
    margin-top:20px;
    /* margin-right:12rpx; */
  }

  .product-slider .product-slider-img {
    display: inline-block;
    /* width:100%; */
    width:100%;
    height:100%;
    top:0;
    left:0;
    position: absolute;
  }

  .product-slider-title {
    margin-top:10px;
    text-align: center;
  }

  @media (max-width: 1024px) {
    .info-right {
       padding:0;
      padding-bottom:4%;
    }

    .product-slider {
      width:48%;
      padding-top:32%;
    }

    .equipment-content /deep/ .carousel-indicators {
      bottom:-20px;
    }
  }

  @media (max-width: 768px) {
    .about-item{
      padding:10px;
    }

    .scale-content {
      padding:10px;
    }

    .content-mian{
      padding:10px;
    }

    .item-block {
      width:50%;
      display: inline-block;
    }

    .item-block-left {
      padding:10px 20px;
      border-left:1px solid #e5e5e5;
    }

    .item-block-title {
      padding-left:16px;
    }

    .scale-item {
      display: block;
      /* width:32%; */
      /* max-height:24px; */
      width:100%;
      height:0;
      padding-top:66.7%;
      margin-top:20px;
      /* margin-right:12rpx; */
    }

    .choose-ul  .size-16 {
      font-size: 12px;
    }
  }
</style>